import {DHXView} from "optimus.js";
import {TreeView} from "views/tree.js";
import {PreviewArea} from "views/leftarea.js";
import {getConfig} from "config/index.js";
import {checkIfPanel} from "helpers/selector.js";

export class TopView extends DHXView{
	render(){
		let top = this.root.attachLayout("3J");
		top.conf.sw = -1;
		top.conf.ofs = { t:-5, b:0,  r:0, l:0 };



		top.setSizes();

		this.app.topCell = top;

		if(this.app.akElm){
			let oWinCell = this.app.akElm.dynObject.container.controller.dhx;
			
			oWinCell.attachEvent('onResizeFinish', () => {
				top.setSizes();
			});
		}

		

		top.setSeparatorSize(0, 10);
		top.setSeparatorSize(1, 10);

		top.cells("b").fixSize(false, false);
		top.cells("b").hideHeader();
		top.cells("b").setMinWidth(200);
		top.cells("c").hideHeader();


		// this.show(BarView,  		top);
		this.show(PreviewArea, 		top.cells("a"));
		this.show(TreeView, 		top.cells("b"));

		let model = this.app.getService("UIState");

		var item;


		this.app.attachEvent("store:onCursor", (id, prev, data, beforeSelectStop) => {
			var cursor = model.getItem(id);
			var isCustomPanel = true;//by default it will be a custom one

			if(checkIfPanel(cursor)) {
				if (!cursor.protect) {
					item = id;
					return true;
				} else {
					isCustomPanel = true;
				}
			}

			let objectConfig = getConfig(data, isCustomPanel);

			if(this.app.config.onBeforeSelect){
				let promiseBeforeSelect = null;
				if(typeof(beforeSelectStop) == "undefined"){
					promiseBeforeSelect = this.app.config.onBeforeSelect(objectConfig, cursor);
				}
				if(promiseBeforeSelect !== null && promiseBeforeSelect.done !== undefined){
					promiseBeforeSelect.done((objectConfig) => {
						if(objectConfig){
							this.app.propertyGrid.parseAttributes(objectConfig);
							item = id;
						}
						
						
					});
				}
					
			}else{
				item = id;
			}
			
		});
	}
}