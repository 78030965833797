var seed = 0;
export function uid(){
	return ++seed;
}

export function toNode(n){
	if (typeof n === "string")
		return document.getElementById(n) || document.querySelector(n);
	return n;
}

export function event(node, name, handler){
	node.addEventListener(name, handler);
}

export function delay(code){
	window.setTimeout(code, 1);
}

export function addEventSystem(obj){
	obj = obj || {};
	let evs = {};

	obj.detachEvent = function(config){
		for (var key in evs){
			var line = evs[key];
			for (var i = line.length - 1; i >= 0; i--)
				if (line[i].config.tag === config.tag)
					line.splice(i,1);
		}
	};

	obj.attachEvent = function(name, code, config){
		name = name.toLowerCase();
		config = config || {};

		let stack = evs[name] || [];

		stack.push({ code, config });
		evs[name] = stack;
	};

	obj.callEvent = function(name, args){
		name = name.toLowerCase();
		let stack = evs[name];
		let result = true;

		if (stack)
			for (let i=0; i<stack.length; i++){
				let line = stack[i];
				let bind = line.config.bind || this;
				result = line.code.apply(bind, args);
			}
	
		return result;
	};
}


export function copy(source){
	var target = Array.isArray(source)?[]:{};

	for (var method in source){
		var from = source[method];
		if(from && typeof from == "object"){
			if (!(from instanceof Date)){
				target[method] = copy(from);
			} else
				target[method] = new Date(from);
		} else {
			target[method] = from;
		}
	}
	return target;	
};