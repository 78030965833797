export const tab = [
	{
		label: "Tab",
		type: "group"
	},	
	{
		name: "titl_tab",			
		label: "Title",
		type: "input"		
	},
	{
		name: "active",			
		label: "Active",
		type: "checkbox"		
	}
];