export const editor = [
	{
		label:"editor",
		type:"group"
	},{
		name:"content",
		label: "Content",
		type:"input"
	},{
		name:"htmlContent",
		label: "HTML content",
		type:"input"
	}
]