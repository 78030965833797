export function extractId(e){
	let cursor = e.target || e.srcElement;
	while(cursor){
		if(cursor.$id)
			return {id:cursor.$id, src: cursor};
		
		cursor = cursor.parentNode;
	}

	return null;
}

function findElement(data ,id){
	let baseLayout = data.cont;
	
	if(data.items){
		data = data.items;
	}else{
		data = data.cont.childNodes; // if tabbar parent
	}
	let len = data.length;
	for(let i = 0; i<len; i++){
		let result = '';
		if(data[i].cell){
			result = checkChilds(data[i].cell, id);
		}
		if(result){
			result = showBorder(result);
			return result;
		}
	}

	return showBorder(baseLayout);
}

function checkChilds(data, id){
	if(data == undefined){

		return 0;
	}else{
		let resdata = data.childNodes;

		// in case we have a initial tabbar 
		if(resdata == undefined && data.tabbar){
			resdata = data.tabbar.cont.childNodes;
		}
		if(data.$id === id){
			return data;
		} else if(resdata){
			let len = resdata.length;
			for(let i = 0; i<len; i++){
				let result = checkChilds(resdata[i], id);
				if(result){
					return result;
				}
			}
		} else {
			return 0;
		}
	}

	
}


var border;
export function showBorder(src, id){
	try {
		if (id)
			src = findElement(src, id);
		if (!src || src.$id === undefined)
			return;

		var rect = src.getBoundingClientRect();
		var height = src.style.height;
		var width = src.style.width;

		var div = document.createElement("div");
		div.classList.add('designer-preview-selector');
		div.id = src.$id;
		div.style.cssText = `
			position:absolute;
			z-index:100;
			top:${rect.top}px;
			bottom:${rect.bottom}px;
			left:${rect.left}px;
			right:${rect.right}px;
			zIndex:999;
			border:3px solid #0050ff;
			pointer-events:none;
			height:${parseInt(height) - 5}px;
			width:${parseInt(width) - 5}px;`;

		if (border && border.parentNode)
			border.parentNode.removeChild(border);

		border = document.body.appendChild(div);	
	} catch (e) {
		console.error(e);
	}
	
}

export function checkIfPanel(element){
	let panels = [];
	if(typeof element == "string") {
		return element && panels.indexOf(element) != -1;
	} else if(element) {
		return element.ui && panels.indexOf(element.ui) != -1;
	} else{
		return false;
	}
}