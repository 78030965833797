export const sidebar = [
	{
		label: "Sidebar",
		type: "group"
	},
	{
		label: "Tabs count",
		name: "quantity",
		type: "input",
		fieldType: "int"
	}
];