// var treeMenu = [
//     {id:"layout", text:"Layout"},
//     {id:"grid", text:"Grid"},
//     {id:"form", text:"Form"},
//     {id:"treeview", text:"Tree View"},
//     {id:"html", text:"HTML Template"},
//     {id:"controls", text:"Controls", items:[
//         {id:"calendar", text:"Calendar"},
//         {id:"colorpicker", text:"Colorpicker"},
//         {id:"combo", text:"Combo"},
//         {id:"editor", text:"Editor"},
//         {id:"slider", text:"Slider"},
//         {id:"toolbar", text:"Toolbar"},
//         {id:"ribbon", text:"Ribbon"}    
//     ]},
//     {id:"widgets", text:"Widgets", items:[
//         {id:"dataview", text:"DataView"},
//         {id:"list", text:"List"},
//         {id:"treegrid", text:"TreeGrid"},
//         {id:"chart", text:"Chart"},
//         {id:"tree", text:"Tree"}
//     ]},
//     {id:"panels", text:"Panels", items:[
//         {id:"accordion", text:"Accordion"},
//         {id:"carousel", text:"Carousel"},
//         {id:"tabbar", text:"Tabbar"},
//         {id:"sidebar", text:"Sidebar"}
//     ]}
// ];
var treeMenu = [
    { id: "remove", text:"Remove"},
    { id: "removePage", text: "Remove Page"},
    { id: "addpage", text:"Add new page"},
    { id: "replace", text:"Replace"},
    { id: "launchMaster", text: "Open Object Master"}
];

var topMenu = [
    { id:"undo", type:"button", text:"Undo", img:"fa fa-undo" },
    { id:"redo", type:"button", text:"Redo", img:"fa fa-undo" },
    
    { id:"reset", type:"button", text:"Reset", img:"fa fa-file-code-o" }
];

export class MenuBuilder{
    constructor(ev){
        this.menu = [].concat(treeMenu);
        // this.toolbar = [].concat(topMenu);
        this.ev = ev;

        this.ev.attachEvent("onCustomize", (config) => {
            if (config.menu)
                this.extend(this.menu, config.menu);
            if (config.toolbar)
                this.extend(this.toolbar, config.toolbar);
        })
    }

    extend(source, alter){
        if (alter.$add){
            if (Array.isArray(alter.$add))
                source.push.apply(source, alter.$add);
            else
                source.push(alter.$add);
        }
    }

    getTreeMenu(){
        return this.menu;
    }
    getTopMenu(){
        return this.toolbar;
    }
};