export const carousel = [
	{
		label:"Carousel",
		type:"group"
	},{
		label: "Tabs count",
		name: "quantity",
		type: "input",
		fieldType: "int"
	}
];