export const layout = [
	// {
	// 	label:"Layout",
	// 	type:"group"
	// },{
	// 	name:"type",
	// 	options:["1C", "2E","2U","3E","3L","3W", "3J", "3T", "3U",
	// 		"4H", "4I", "4T", "4U", "4E", "4A", "4W", "4L", "4J", "4F", "4G", "4C",
	// 		"5H", "5I", "5U", "5E", "5W", "5K", "5S", "5G", "5C",
	// 		"6H", "6A", "6C", "6J", "6E", "6W",
	// 		"7H", "7I"
	// 	],
	// 	type:"select"
	// },{
	// 	name:"id",
	// 	type:"input"
	// },{
	// 	name:"enabled",
	// 	type:"checkbox"
	// }
];