import {copy} from "betterdhx.js";

function cell(a){
	return {
		ui: "empty",
		config: {
            $objicon: window.akioma.repository.getObjIcon('empty'),
            $objstyle: window.akioma.repository.getObjStyle('empty')
          },
	};
}

export function initialState(name){
	const states = {
		tabbar      :{ cells:[ cell("a"), cell("b") ]  },
		sidebar     :{ cells:[ cell("a"), cell("b") ]  },
		accordion   :{ cells:[ cell("a"), cell("b") ]  },
		carousel    :{ cells:[ cell("a"), cell("b") ]  },
		layout      :{ type:"2E", cells:[ cell("a"), cell("b") ] },
		grid		:{ columns:"First Name, Last Name", ids:"fname,lname", widths:"200, *" }
	};
	return copy(states[name] || {});
}

export function changeState(id, values, model){

	if(Object.keys(values)[0].indexOf('_pagelabel') > -1){
		values.title = values[Object.keys(values)[0]];
		model.getItem(id).config.title = values.title;
	}

	// var aElms = Object.values(model.pull);
	var aElms = [];
	_.forEach(model.pull, function(e, k) {
	    // e[0].connector = k;
	    aElms.push(e);
	});

	aElms = _.filter(aElms, { "$parent": id });
	var iExtra = 0;
	var iExtraNo = 0;
	// check if placeholder with original type swatbusinessentity
	
	for(var i in aElms){
		let elm = aElms[i];
		let bBePlaceholder = false;
		try{
			bBePlaceholder = (elm.ui == 'placeholder' && elm.config.$orgobjtype.toLowerCase() == 'swatbusinessentity');

		}catch(e){
			console.log(e)
		}

		if(elm.ui == 'sdo' || elm.ui == 'businessEntity' || elm.ui == 'swatsdo' || elm.ui == 'SwatBusinessEntity' || bBePlaceholder){
			iExtra++;
		}else{
			iExtraNo++;
		}
	}



	if(values.quantity)
		model.fixChildItems(id, values.quantity*1);
	else if (values.type){
		model.fixChildItems(id, values.type.substr(0,1)*1 + iExtra);
	}else if(Object.keys(values)[0].indexOf('_pagetype') > -1 || Object.keys(values)[0].indexOf('_maintype') > -1){
		values.type = values[Object.keys(values)[0]];
		model.getItem(id).config.type = values.type;
		if(model.getItem(id)._custom[0])
			model.getItem(id)._custom[0].value = values.type;
		var iFinal = values.type.substr(0,1)*1 + iExtra;
		model.fixChildItems(id, iFinal);
	}

}

export function emptyCell(a, config){
	let ui = cell(a || "x");
	if (config)
		for (var key in config)
			ui.config[key] = config[key];

	return ui;
}