import "less/designer.less";
import {DHXApp} from "optimus.js";
import {TopView} from "views/top.js";
import {UIDataStore} from "models/uistore.js";
import {UISnippets} from "models/snippets.js";
import {MenuBuilder} from "models/menu.js";
import {PreviewManager} from "preview/index.js";

class DesignerApp extends DHXApp{
	constructor(config){
		super(config);

		this.akController = config.self;

		this.addService("UIState", new UIDataStore(this.events, config));
		this.addService("UISnippets", new UISnippets(this.events, config));
		this.addService("UIMenu", new MenuBuilder(this.events, config));
  
		this._setEvents();
	}

	get previewManager() {
		const iframe = this.root.cell.querySelector('iframe');
		const iframeWin = iframe.contentWindow;
		return iframeWin.designerPreviewManager
	}

	static initPreview(host, owner){
		return new PreviewManager(host, owner);
	}

	_setEvents(){}

	render(){
		this.show(TopView);

		this.callEvent("onAppRender",[]);
	}

	customize(data){
		this.callEvent("onCustomize", [data]);
	}

	addSnippet(data){
		this.getService("UISnippets").add(data);
	}

	loadConfig(cf){
		const iframe = this.root.cell.querySelector('iframe');
		this.removeDesignerPreviewSelector(iframe);
		this.getService("UIState").parse(cf);
	}
	getConfig(){
		return this.getService("UIState").serialize();
	}

	/**
	 * Remove designer previews selector
	 * @param {HTMLElement} iframe
	 * @memberof DesignerApp
	 * @instance
	 */
	removeDesignerPreviewSelector(iframe) {
		$(iframe).contents().find('.designer-preview-selector').remove();
	}
}

window.DesignerApp = DesignerApp;
