import {uid} from "betterdhx.js";

export class UndoStack{
    constructor(model, ev, config){
		this.model = model;
        this.bus = ev;
		this.config = config || {};

        this.bus.attachEvent("onCommand", (id)=>{
			if(id==="undo"){
				this.do(-2,1);
			} else if(id==="redo"){
				this.do(0,1);
			}
		});

        this.reset();

		if (this.config.autoSave)
			this.bus.attachEvent("onAppRender", () => this._restore() );
    }

	_restore(){
		var state = localStorage.getItem("dhx:designer:work-state");
		if (state){
			this._stack = [state];
			this._cursor = 0;
			this._applyState(this.model, state);
		}
		this.bus.callEvent("store:onDataLoad",[]);
	}

    do(undo, redo){
		let cursor = this._cursor + undo;
		if(cursor < 0 || cursor >= this._stack.length)
			return;

		this._cursor += undo;
		this.pop(this.model);
		this._cursor += redo;
	}

	push(model){
		if(this._cursor < this._stack.length)
			this._stack = this._stack.slice(0, this._cursor);
		
		var state = JSON.stringify(model.branch);
		this._stack.push(state);
		this._cursor = this._stack.length;

		if (this.config.autoSave)
			localStorage.setItem('dhx:designer:work-state', state);		
    }

    pop(model){
        var state = this._stack[this._cursor];
		this._applyState(model, state);
        		
		if (this.config.autoSave)
			localStorage.setItem('dhx:designer:work-state', state);

		this.bus.callEvent("store:onUndo");
	}

	_applyState(model, state){
		model.pull = [];
		model.branch = JSON.parse(state);
		
		var maxId = 0;
		for (let key in model.branch){
			for (let i = model.branch[key].length-1; i>=0; i--){
				var item = model.branch[key][i];
				model.pull[item.$id] = item;
				if (item.$id > maxId) maxId = item.$id;
			}
        }

		//ensure that new id will be unique
		while(uid() < maxId){ /*do nothing*/ };
	}

    reset(){
        this._stack = [];
		this._cursor = 0;
    }
}