export const cell = [
	// {
	// 	name: "width",
	// 	label: "Width",
	// 	type: "input",
	// 	fieldType: "int"
	// },{
	// 	name: "height",
	// 	label: "Height",
	// 	type: "input",
	// 	fieldType: "int"
	// },{
	// 	name: "fixsize",
	// 	label: "Fixed size",
	// 	type: "checkbox"
	// },{
	// 	name: "header",
	// 	label: "Header",
	// 	type: "input"
	// },{ 
	// 	name: "hide_header",    
	// 	label: "Hide header",
	// 	type: "checkbox"
	// },{ 
	// 	name: "toolbar",    
	// 	label: "Toolbar",
	// 	type: "checkbox"
	// },{ 
	// 	name: "ribbon",    
	// 	label: "Ribbon",
	// 	type: "checkbox"
	// },{ 
	// 	name: "menu",    
	// 	label: "Menu",
	// 	type: "checkbox"
	// },{ 
	// 	name: "statusbar",    
	// 	label: "Status Bar",
	// 	type: "checkbox"
	// }
];