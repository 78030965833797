export function toTreeJson(model){
	if (!model.branch[0] || !model.branch[0].length) return {};
	let res = _build_level(model, { $id:0, ui:"none", config:{} });
	return res;
}

function _build_level(model, item){
	const obj = { id: item.$id };

	obj.text = (item.config.$name || item.ui);

	obj.icon = item.config.$objicon;
	obj.style = item.config.$objstyle;

	if(item.$parent === 0){
		obj.open = true;
	}
	
	// Apply "cog" contextmenu only on items that are not nothing and not the root
	if (item.$parent !== 0 && item.ui !== "empty")
		obj.text += " <i design_id='"+item.$id+"' class='fa fa-cog'></i>";

	// Restore expand states if previous states available
	if (model.previousOpenStates) {
		const previousOpenState = model.previousOpenStates.shift();
		
		if (previousOpenState)
			obj.open = true;
	}

	const branch = model.branch[item.$id];
	if (branch){
		const item = [];
		for (var i = 0; i < branch.length; i++){
			const child = branch[i];
			item.push(_build_level(model, child));
		}
		obj.item = item;
	}
	return obj;
}