export const slider = [
	{
		label:"Slider",
		type:"group"
	},{
		name:"tooltip",
		label: "Tooltip",
		type:"checkbox"
	},{
		name:"minValue",
		label: "Min value",
		type:"input"
	},{
		name:"maxValue",
		label: "Max value",
		type:"input"
	},{
		name:"size",
		label: "Size of the control",
		type:"input"
	},{
		name:"skin",
		label: "Skin",
		options:["dhx_skyblue","dhx_web","dhx_terrace"],
		type:"select"
	},{
		name:"step",
		label: "Step value",
		type:"input"
	},{
		name:"currentValue",
		label: "Current value",
		type:"input"
	}
];