import {toNode,addEventSystem} from "betterdhx.js";

class LocalSession{
	get(key, def){
		var value = window.localStorage.getItem(key);
		return value === null ? def : value; 
	}
	set(key, value){
		window.localStorage.setItem(key, value);
	}
}

export class DHXApp{
	constructor(config){
		addEventSystem(this);

		//event bus facade
		this.session = new LocalSession();
		this.events = {
			attachEvent: this.attachEvent,
			callEvent: this.callEvent
		};

		this.services = {};
		this.config = config || {};

		//create wrapper for root node
		let root = toNode(this.config.container || document.body);
		if (!root.tagName)
			this.root = root;
		else
			this.root = {
				root,
				attachLayout:function(config){
					return new dhtmlXLayoutObject(this.root, config);
				}
			};
	}
	show(view){
		var t = new view(this, this.root);
		t.render();

		return t;
	}

	addService(name, obj){
		this.services[name] = obj;
	}
	getService(name){
		return this.services[name];
	}

	imagepath(comp){
		return this.config.images+"/dhx"+comp+"_"+this.config.skin+"/";
	}
	destructor(){
		this.services = this.events = this.root = this.config = null;
	}
}


export class DHXView{
	constructor(app, root){
		this.app = app;
		this.root = root;
	}
	show(view, cell){
		var t = new view(this.app, cell);
		t.render();

		return t;
	}

	attachEvent(name, handler){
		this.app.attachEvent(name, handler, { bind: this, tag: this });
	}

	callEvent(name, params){
		this.app.callEvent(name, (params || []));
	}

	render(){
		dhtmlx.message("Render method is not implemented for the view");
	}

	destructor(){
		this.app.detachEvent({ tag: this });
		this.model = this.ui = this.app = this.root = null;
	}
}