export const accordion = [
	{
		label: "Accordion",
		type: "group"
	},
	{
		label: "Tabs count",
		name: "quantity",
		type: "input",
		fieldType: "int"
	}
];