export const tabbar = [
	{
		label: "Tabbar",
		type: "group"
	},
	{
		label: "Tabs count",
		name: "quantity",
		type: "input",
		fieldType: "int"
	},
	{
		name: "align",			
		label:" Align",
		options:["right","left"],
		type:"select"
	},
	{
		name: "close_button",			
		label: "Close button",
		type: "checkbox"		
	}
];