export const tree = [
	//main group
	{
		label: "Tree",
		type: "group"
	},
	{
		name: "header",
		label: "Header",
		type: "input"
	},
	{
		name: "rtl",
		label: "Enable RTL",
		type: "checkbox"
	},
	{
		name: "hide_lines",
		label: "Hide treelines",
		type: "checkbox"
	},
	{
		name: "open_tree",
		label: "Open items",
		type: "checkbox"
		
	},
	{
		name: "lock_tree",
		label: "Lock tree",
		type: "checkbox"
	},
	{
		name: "auto_tooltip",
		label: "Enable auto tooltips",
		type: "checkbox"
	},
	{
		name: "child_calc",
		label: "Child calculation mode",
		type: "checkbox"
	},
	{
		name: "calc_pref",
		label: "Calculator prefix",
		type: "input"
	},
	{
		name: "calc_postf",
		label: "Calculator postfix",
		type: "input"
	},
	
	//image group
	{
		label: "Images manipulations",
		type: "group"
	},
	{
		name: "hide_images",
		label: "Hide images",
		type: "checkbox"
	},
	{
		name: "img_height",
		label: "Images height",
		//value:"18",
		type: "input"
	},
	{
		name: "img_width",
		label: "Images width",
		//value:"18",
		type: "input"
	},
	{
		name: "active_img",
		label: "Lock images",
		type: "checkbox"
	},
	{
		name: "text_signs",
		label: "Enable text signs",
		type: "checkbox"
	},
	
	// checkbox group
	{
		label: "Checkbox support",
		type: "group"
	},
	{
		name: "ch_enable",
		label: "Enable checkboxes",
		type: "checkbox"
	},
	{
		name: "three_st_ch",
		label: "Three-state checkboxes",
		type: "checkbox"
	},
	{
		name: "smart_ch",
		label: "Smart checkboxes",
		type: "checkbox"
	},
	
	// multiline group
	{
		label: "Multiline support",
		type: "group"
	},
	{
		name: "multiline",
		label: "Enable multiline",
		type: "checkbox"
	},
	{
		name: "line_width",
		label: "Line width",
		type: "input"
	},
	
	// keyboard group
	{
		label: "Keyboard features",
		type: "group"
	},
	{
		name: "key_nav",
		label: "Enable keyboard",
		type: "checkbox"
	},
	{
		name: "autosearch",
		label: "Enable autosearch",
		type: "checkbox"
	},
	{
		name: "editor",
		label: "Enable edit",
		type: "checkbox"
		
	},
	
	//cookie saving
	{
		label: "Saving tree state to cookies",
		type: "group"
	},
	{
		name: "cookie_enable",
		label: "enable saving",
		type: "checkbox"
	},
	{
		name: "cookie_name",
		label: "Cookie name",
		type: "input"
	},
	
	//select group
	{
		label: "Selection",
		type: "group"
	},
	{
		name: "focus_item",
		label: "Focus item",
		type: "input"
	},
	{
		name: "select",
		label: "Select item",
		type: "input"
	},
	{
		name: "highlight",
		label: "Enable highlighting",
		type: "checkbox"
	},
	{
		name: "multiselect",
		label: "Enable multiselection",
		type: "checkbox"
	},
	{
		name: "interlevel",
		label: "Interlevel multiselection",
		type: "checkbox"
	},
			
	//drag and drop group
	{
		label: "Drag-and-drop",
		type: "group"
	},
	{
		name: "dnd_enable",
		label: "Enable drag-and-drop",
		type: "checkbox"
	},
	{
		name: "drag_behave",
		label: "Set drag behavior",
		options:["","child","complex","sibling"],
		type:"select"
	},
	{
		name: "mercy_drag",
		label: "Enable mercy drag",
		type: "checkbox"
	},
	{
		name: "empty_drop",
		label: "Block dropping on an empty space",
		type: "checkbox"
	},
	{
		name: "image_drag",
		label: "Image dragging",
		type: "checkbox"
	},
	
	//data loading group
	{
		label: "Data loading",
		type: "group"
	},
	{
		name: "cache",
		label: "Prevent data caching",
		type: "checkbox"
	},
	{
		name: "smart_parse",
		label: "Smart XML parsing",
		type: "checkbox"
	},
	{
		name: "distrib_parse",
		label: "Distributed Parsing",
		type: "checkbox"
	}
];