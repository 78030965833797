export class UISnippets{
    constructor(ev){
        this.ev = ev;
        this.pull = {};
    }

    add(data){
        let arr;
        if (!Array.isArray(data))
        	arr = [data];
        else
            arr = data;

        for (var i = 0; i < arr.length; i++)
            this.pull[arr[i].id] = arr[i];

        this.ev.callEvent("snippet:onDataRefresh",[this]);
    }

    getItem(id){
        return this.pull[id];
    }
}