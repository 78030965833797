import {accordion} from "config/accordion.js";
import {calendar} from "config/calendar.js";
import {carousel} from "config/carousel.js";
import {cell} from "config/cell.js";
import {colorpicker} from "config/colorpicker.js";
import {editor} from "config/editor.js";
import {grid} from "config/grid.js";
import {html} from "config/html.js";
import {index} from "config/index.js";
import {layout} from "config/layout.js";
import {sidebar} from "config/sidebar.js";
import {slider} from "config/slider.js";
import {tab} from "config/tab.js";
import {tabbar} from "config/tabbar.js";
import {tree} from "config/tree.js";

const configs = {
	accordion, calendar, carousel, cell, colorpicker, editor,
	grid, html, index, layout, sidebar, slider, tab, tabbar, tree
};

export function getConfig(object, panel){
	var options = [].concat(cell);
	if (!object.protect && configs[object.ui]) {
		options = options.concat(configs[object.ui]);
		if (object._custom) options = options.concat(object._custom);
	} else if(object._custom && Array.isArray(object._custom)) {
		options = panel ? [].concat(object._custom) : options.concat(object._custom);;
	}
		
	return options;
}