import {DHXView}    from "optimus.js";
import {event, delay}      from "betterdhx.js";
import {toTreeJson} from "helpers/treejson.js";
import {TreeMenu} from "views/menus/tree.js";
import { checkIfPanel } from "helpers/selector.js";


export class TreeView extends DHXView {
	render() {
		let app = this.app;
		let dndEnabled = !!(app.config ? app.config.dnd : false);

		let model = app.getService("UIState");
		let tree = this.root.attachTree();

		if (dndEnabled) {
			tree.enableDragAndDrop(dndEnabled);
		}

		tree.setSkin("dhx_material")
		tree.setImagesPath("dhtmlx/dhx5-designer-sources/codebase/dhtmlx/imgs/dhxtree_material/");
		tree.parse(toTreeJson(model), "json");

		var menu = this.show(TreeMenu);

		event(this.root.cell, "click", function(e){
			var trg = e.target || e.srcElement;
			var css = trg.className;
			if (css && css.indexOf("fa-cog") !== -1){
				var id = trg.getAttribute("design_id");

				var pos = {x: e.clientX, y: e.clientY};
				delay(() => menu.ui.showContextMenu(pos.x, pos.y));
			}
		});

		//update tree afte changes in model
		this.attachEvent("store:onDataLoad", () => {
			tree.deleteChildItems(model.getRoot().$parent);
			tree.parse(toTreeJson(model), "json");
		});
		this.attachEvent("store:onDataRefresh", () => {
			tree.deleteChildItems(model.getRoot().$parent);
			tree.parse(toTreeJson(model), "json");
		});
		this.attachEvent("store:onCursor", (id) => tree.selectItem(+id));

		//trigger selection event
		tree.attachEvent("onSelect", (id, mode) => {
			model.setCursor(+id);
		});

		if (dndEnabled) {

			tree.attachEvent("onBeforeDrag", function (id) {
				return false;
			});

			tree.attachEvent("onDragIn", function (sId, tId, sObj, tObj) {				
				if (!tId) {
					return false;
				}


				// check type of object that has been dropped, if object has been dropped and is of type SwatBusinessEntity
				// it will disable drag&drop area for businesssEntity nodes
				let oitmBE = sObj.BE.item(sObj.cell.parentNode.idd);
				let item = model.getItem(tId);
				if (oitmBE) {
					const typeName = oitmBE.objecttypename || oitmBE.instancetypename;
					if(typeName.toLowerCase() == 'swatbusinessentity'){
						let bCont = (item.ui != 'layout');
						let bReplaceBE = (item.ui == 'swatbusinessentity');
	
						if(bCont && !bReplaceBE){
							return false;
						}
					} 
				}
				


				if(app.config.getCustomIemType) {
					let type = app.config.getCustomIemType(sObj, sId, tObj, tId);
					
					if((checkIfPanel(type) && !checkIfPanel(item.ui)) || 
						(!checkIfPanel(type) && item && checkIfPanel(item.ui))) {
						return false;
					}
				}
				return true;
			});

			tree.attachEvent("onDrop", function(sId, tId, id, sObject, tObject){
			    // your code here
			    console.log(sId, tId, id, sObject, tObject);
			    return true;
			});


			tree.attachEvent("onDrag", function (sId, tId, id, sObject, tObject) {
				
				let snippetId = sId.indexOf('c_') != 0 ? 'c_' + sId : sId;
				let snippet = app.getService("UISnippets");
				let ui = "";
				let cNewUniqueInstanceName = '';
				let cNewInstanceGuid = '';
				let cOriginalObjType = '';
				let oNewRecData; 

				let targetElm = model.getItem(tId);

				if (app.config.getCustomIemType) {
					ui = app.config.getCustomIemType(sObject, sId);
				}

				const allowedRootDropTypes = ['swatbusinessentity', 'swattoolbar', 'ribbon'];

				if(targetElm && targetElm.$parent === 0 && allowedRootDropTypes.indexOf(ui) === -1){
					dhtmlx.message("Replacing the master is not a supported operation.");
					return false;
				}

				if (app.config.onDragCallback) {

					oNewRecData = app.config.onDragCallback(sObject, sId, tObject, tId, this);

					cNewUniqueInstanceName = oNewRecData.instancename;
					cNewInstanceGuid = oNewRecData.guid;
				}

				if (app.config.getCustomIemType) {
					ui = app.config.getCustomIemType(sObject, sId);
					cOriginalObjType = ui;

					if (!checkIfPanel(ui) && ui != 'akgridcol' && ui != 'swattoolbar' && ui != 'swatribbon') {
						ui = "abstract";
					}
					if(cOriginalObjType == 'ribbon')
						ui = 'ribbon';
					if(cOriginalObjType == 'swatbusinessentity'){
						ui = 'swatbusinessentity';
					}
				}

				let data;

				if(cOriginalObjType == 'tabbar'){
					data = {
						id: snippetId,
						ui: cOriginalObjType,
						$id: snippetId,
						$objtype: cOriginalObjType,
						guid: cNewInstanceGuid,
						header: cNewUniqueInstanceName,
						instancename: cNewUniqueInstanceName,
						config: {
							header: cNewUniqueInstanceName,
							$name: cNewUniqueInstanceName + '/' + cOriginalObjType,
							$objicon: akioma.repository.getObjIcon(cOriginalObjType.toLowerCase()),
							$objstyle: akioma.repository.getObjStyle(cOriginalObjType),
							$objtype: cOriginalObjType,
							$objname: cNewUniqueInstanceName
							
						}
					};
				}else{
					data = {
						id: snippetId,
						ui: ui,
						$id: snippetId,
						$objtype: cOriginalObjType,
						guid: cNewInstanceGuid,
						header: cNewUniqueInstanceName,
						instancename: cNewUniqueInstanceName,
						config: {
							header: cNewUniqueInstanceName,
							$name: cNewUniqueInstanceName + '/' + cOriginalObjType,
							$objicon: akioma.repository.getObjIcon(cOriginalObjType.toLowerCase()),
							$objstyle: akioma.repository.getObjStyle(cOriginalObjType),
							$objtype: cOriginalObjType,
							$objname: cNewUniqueInstanceName

						}
					};
				}

				

				let bIsPromise = true;

				if(app.config.getCustomProperties) {
					let customConfig = app.config.getCustomProperties(sObject, sId);

					// now check if jquery promise returned and if so load after getting new attributes
					// 
					// 

					if(customConfig.then != undefined)
						bIsPromise = true;


					// this means that a new business entity has been added stop execution of replacement
					if(oNewRecData === false){
						customConfig.done(function(oResult){
							let customConfig = oResult;
							if(Array.isArray(customConfig)) {
								let oItem = model.getItem(model._cursor);
								oItem._custom = customConfig;
								model._cursor = '';
								model.bus.callEvent("store:onCursor", [oItem.$id, 0, oItem]);
								model.setCursor(oItem.$id);
							}
						});
						return false;
					}


					if(bIsPromise){
						customConfig.done(function(oResult){
							let customConfig = oResult;
							if(Array.isArray(customConfig)) {
								data._custom = customConfig;
							}

							app.addSnippet(data);				

							let item = model.getItem(+tId);

							if (checkIfPanel(ui)) { 
								var values = {};				

								values[item.ui] = false;
								values[ui] = true;
								
								model.replace(item.$id, snippet.getItem(snippetId));
								item = model.getItem(item.$parent);

								model.updateItem(item.$id, values);
								model.bus.callEvent("store:onCursor", [item.$id, 0, item]);
							} else {
								const oldRecord = _.clone(item);
								let aSpecials = window.akioma.repository.LayoutDesigner.navigationReplaceKeepInstanceTypes;
								model.replace(item.$id, snippet.getItem(snippetId), false);

								model.bus.callEvent("store:onCursor", [item.$id, 0, item]);

								let el = snippet.getItem(snippetId);
								
								if(aSpecials.indexOf(el.ui) > -1){
									try{ 
			                            let aExisting = model.branch[item.$parent] || [];
			                            aExisting.splice(aExisting.indexOf(item)+1, 0, oldRecord);

			                            model.pull = _.omit(model.pull, function(item){
			                            	return aExisting.indexOf(item) > -1
			                            }); 

			                            model.branch[item.$parent] = aExisting;
			                        
			                            model._parse_rec(item.$parent, aExisting);

			                            model.bus.callEvent("store:onDataRefresh",[{}]);
										model._cursor = '';
                                		model.setCursor(item.$id);
			                           

			                        }catch(e){
			                            console.warn('Adding to tree branch failed!', e)
			                        }
								}
							}

						});
					}else{
						if(Array.isArray(customConfig)) {
							data._custom = customConfig;
						}
					}
					
				}


				// if it is a promise stop the rest, adding the new object with attributes custom
				if(bIsPromise)
					return true;



				app.addSnippet(data);				

				let item = model.getItem(+tId);

				if (checkIfPanel(ui)) { 
					var values = {};				

					values[item.ui] = false;
					values[ui] = true;
					
					model.replace(item.$id, snippet.getItem(snippetId));
					item = model.getItem(item.$parent);

					model.updateItem(item.$id, values);
					model.bus.callEvent("store:onCursor", [item.$id, 0, item]);
				} else {
					model.replace(item.$id, snippet.getItem(snippetId));
				}

				return true;
			});
		}
	}
}