import {uid, copy} from "betterdhx.js";
import {UndoStack} from "helpers/undo.js";
import {initialState, changeState, emptyCell} from "helpers/constructor.js";

export class UIDataStore{
	constructor(bus, config){
		this.bus = bus;
		this.undo = new UndoStack(this, bus, config);
		this.config = config;

		this.reset();

		this._cursor = null;
		this.initEvents();
	}

	initEvents(){
		this.bus.attachEvent("store:onDataChange", () => this.undo.push(this) );
		this.bus.attachEvent("store:onDataRefresh", (obj) =>{
			if (obj.type != "undo") this.undo.push(this);
		});
		this.bus.attachEvent("store:onDataLoad", () => {
			this.undo.reset();
			this.undo.push(this)
		});
		this.bus.attachEvent("store:onUndo", () => {
			this.bus.callEvent("store:onDataRefresh", [{ type:"undo" }]);
		});
	}

	getCursor(){ return this._cursor; }
	setCursor(id){
		if (this._cursor !== id){
			let old = this._cursor;
			this._cursor = id;
			this.bus.callEvent("store:onCursor", [id, old, this.getItem(id)]);
		}
	}

	getItem(id){
		return this.pull[id];
	}
	getChildren(id){
		return this.branch[id];
	}
	isEmpty(){
		return !(this.branch[0] && this.branch[0].length);
	}
	getRoot(){
		var top = this.branch[0];
		return top ? top[0] : null;
	}

	updateItem(id, values, silent = false){
		var data = this.getItem(id).config;
		for (var key in values)
			data[key] = values[key];
		
		changeState(id, values, this);		

		if(!silent){
			this.bus.callEvent("store:onDataChange",[id, values]);
		}
	}

	replace(id, newObj, removeFromStore = true){
		var item = this.getItem(id);
		
		this._remove(item, removeFromStore);

		var old_config = item.config;

		if (typeof newObj == "object"){
			item.ui = newObj.ui;
			item.config = newObj.config;
			item.protect = true;
			if(newObj.guid)
				item.guid = newObj.guid;
			if(newObj.instancename)
				item.instancename = newObj.instancename;

			if(newObj.$objtype)
				item.$objtype = newObj.$objtype;

			if(newObj.$objicon)
				item.$objicon = newObj.$objicon;

			if(newObj._custom && Array.isArray(newObj._custom)) {
				item._custom = newObj._custom;
			}
		} else {
			item.ui = newObj;
			item.config = initialState(newObj);
			item.protect = false;
		}

		item.config.width = old_config.width;
		item.config.height = old_config.height;
		item.config.header = (old_config.header || newObj.config.header);

		//parse new child items, if any
		if (item.config.cells){
			this._parse_rec(item.$id, item.config.cells);
			delete item.config.cells;
		}

		this.bus.callEvent("store:onDataRefresh",[{ type:"replace" }]);
	}

	fixChildItems(id, count){
		var branch = this.branch[id];

		// add placeholder with swatbusinessentiy in list

		var dataSourceCount = _.filter(branch, function(item){
			return (item.ui == 'sdo' || item.ui == 'businessentity' || item.ui == 'swatsdo' || item.ui == 'swatbusinessentity' || item.ui == 'ribbon'|| item.ui == 'toolbar' || item.ui == 'swatribbon'|| item.ui == 'swattoolbar')
		}).length;

		count += dataSourceCount;

		// search for childs that might not be empty cells
		// 
		let aInstances = designerApp.akElm.businessEntity.getStore('eSmartObjectInstance').data.pull;

		let parentGuid = this.getItem(id).$parent;

		let pageguid = this.getItem(id).config.id;

		let filterParentIns = '';
		let filterGuid = '';

		if(parentGuid == 0){
			filterGuid = '';
		}

		let results = _.filter(aInstances, function(item){
			return item.parentinstanceguid == filterGuid && 
					(pageguid == undefined || item.pageguid == pageguid);
		})
		let alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
		for (let i=branch.length; i<count; i++){

			var existingCell = _.filter(results, function(item){
				return item.layoutposition == alphabet[i]
			});

			if(existingCell.length > 0){
				let cObjType = designerApp.akElm.getObjType(existingCell[0].instancetypename);

				let oNewObject;

				if(cObjType == 'layout' || cObjType == 'tabbar'){
					let iLayoutPatterns = 2;
	                oNewObject = {
	                    ui: cObjType,
	                    guid: existingCell[0].objectinstanceguid,
	                    order: existingCell[0].sequence,
	                    position: existingCell[0].layoutposition,
	                    config: {
	                        id: existingCell[0].objectinstanceguid,
							$name: existingCell[0].instancename + '/' + cObjType,
							$objicon: akioma.repository.getObjIcon(existingCell[0].instancetypename),
							$objstyle: akioma.repository.getObjStyle(existingCell[0].instancetypename),
	                        type: designerApp.akElm.aTempLayoutOptions[iLayoutPatterns] || '1C',
	                        header: existingCell[0].instancename,
	                        active: "a"
	                    },

	                    cells: []
	                };
					iLayoutPatterns++;
					
	                // if tabbar go over each page and add them, including its children
	                // 
	                if(cObjType == 'tabbar'){
	                	let aPages = designerApp.akElm.businessEntity.getStore('eSmartPage').data.pull;
	                	for(var p in aPages){
		                    var oNewPage = {
		                        ui: 'layout',
		                        config: {
		                            $name: 'Page ' + aPages[p].pagesequence + " / "+ aPages[p].pagelabel,
		                            id: aPages[p].pageguid,
		                            type: designerApp.akElm.aTempLayoutOptions[iLayoutPatterns],
		                            title: aPages[p].pagelabel
		                        },
		                        _custom: [
		                            {
		                                label: "Type",
		                                _idd: '0_'+ aPages[p].pageguid + '_pagetype',
		                                name: "type",
		                                options: ["1C", "2E", "2U", "3E", "3L", "3W", "3J", "3T", "3U", "4H", "4I", "4T", "4U", "4E", "4A", "4W", "4L", "4J", "4F", "4G", "4C", "5H", "5I", "5U", "5E", "5W", "5K", "5S", "5G", "5C", "6H", "6A", "6C", "6J", "6E", "6W", "7H", "7I"],
		                                type: "select",
		                                value:  designerApp.akElm.aTempLayoutOptions[iLayoutPatterns],
		                                // original: oOriginalMasterLayoutOptions
		                            },
		                            {
		                                name: "label",
		                                _idd: '1_'+ aPages[p].pageguid + '_pagelabel',
		                                label: "Label",
		                                type: "input",
		                                value: aPages[p].pagelabel,
		                                original: aPages[p]
		                            },
		                            {
		                                name: "enableoncreate",
		                                _idd: '2_'+ aPages[p].pageguid + '_pageenableoncreate',
		                                label: "EnableOnCreate",
		                                type: "checkbox",
		                                value: aPages[p].enableoncreate,
		                                original: aPages[p]
		                            },
		                            {
		                                name: "enableonmodify",
		                                _idd: '3_'+ aPages[p].pageguid + '_pageenableonmodify',
		                                label: "EnableOnModify",
		                                type: "checkbox",
		                                value: aPages[p].enableonmodify,
		                                original: aPages[p]
		                            },
		                            {
		                                name: "enableonview",
		                                _idd: '4_'+ aPages[p].pageguid + '_pageenableonview',
		                                label: "EnableOnView",
		                                type: "checkbox",
		                                value: aPages[p].enableonview,
		                                original: aPages[p]
		                            },
		                            {
		                                name: "pageicon",
		                                _idd: '5_'+ aPages[p].pageguid + '_pagepageicon',
		                                label: "PageIcon",
		                                type: "input",
		                                value: aPages[p].pageicon,
		                                original: aPages[p]
		                            },
		                            {
		                                name: "securitytoken",
		                                _idd: '6_'+ aPages[p].pageguid + '_pagesecuritytoken',
		                                label: "SecurityToken",
		                                type: "input",
		                                value: aPages[p].securitytoken,
		                                original: aPages[p]
		                            }
		                        ],
		                        guid: aPages[p].pageguid,
		                        cells: []
		                    }
		                    // for each page add the children and empty cells
		                    // 
		                    let aPageChildren = _.chain(aInstances).filter(function(item){
		                    	return item.pageguid == aPages[p].pageguid
		                    }).sortBy('layoutposition').value();


		                    let iChildrenMax = 0;
		                    if(aPageChildren.length > 0)
			                    iChildrenMax = alphabet.indexOf(aPageChildren[aPageChildren.length - 1].layoutposition);
			                
		                    let q = 0;
		                    while(iChildrenMax >= q){
		                    	let cObjTypeChildPage;
		                    	if(aPageChildren.length > 0){

		                    		cObjTypeChildPage = designerApp.akElm.getObjType(aPageChildren[q].instancetypename);
		                    	}
		                    	let oNewChildPageObject;

		                    	if(aPageChildren.length > 0 && alphabet[q] == aPageChildren[q].layoutposition){
		                    		oNewChildPageObject = {
					                    ui: 'abstract',
					                    guid: aPageChildren[q].objectinstanceguid,
					                    order: aPageChildren[q].sequence,
					                    position: aPageChildren[q].layoutposition,
					                    _custom: designerApp.akElm.props[aPageChildren[q].objectinstanceguid],
					                    config:{
					                        $name: aPageChildren[q].instancename + '/' + cObjTypeChildPage,
					                        header: aPageChildren[q].instancename,
											$objicon: akioma.repository.getObjIcon(cObjTypeChildPage),
					                        $objicon: akioma.repository.getObjStyle(cObjTypeChildPage),
					                        $objtype: cObjTypeChildPage,
					                        $objname: aPageChildren[q].instancename
					                    }
					                };
		                    	}else{
		                    		oNewChildPageObject = emptyCell();
		                    	}
		                    	
		                    	oNewPage.cells.push(oNewChildPageObject);
		                    	q++;
		                    }


		                    oNewObject.cells.push(oNewPage);
		                    iLayoutPatterns++;
		                }

	                }

	            }else if(cObjType == 'ribbon'){
	                oNewObject = {
	                    ui: 'ribbon',
	                    guid: existingCell[0].objectinstanceguid,
	                    order: existingCell[0].sequence,
	                    position: existingCell[0].layoutposition,
	                    _custom: designerApp.akElm.props[existingCell[0].objectinstanceguid],
	                    config:{
							$name: cObjType + '/' + existingCell[0].instancename,
							$objicon: akioma.repository.getObjIcon(existingCell[0].instancetypename),
							$objstyle: akioma.repository.getObjStyle(existingCell[0].instancetypename),	
	                        $objtype: cObjType
	                    }
	                };

	            }else if(cObjType == 'toolbar' || cObjType == 'swattoolbar'){
	                oNewObject = {
	                    ui: cObjType,
	                    guid: existingCell[0].objectinstanceguid,
	                    order: existingCell[0].sequence,
	                    position: existingCell[0].layoutposition,
	                    _custom: designerApp.akElm.props[existingCell[0].objectinstanceguid],
	                    config:{
	                        $name: existingCell[0].instancename + '/' + cObjType,
							$objicon: akioma.repository.getObjIcon(existingCell[0].instancetypename),
							$objstyle: akioma.repository.getObjStyle(existingCell[0].instancetypename),	
	                        $objtype: cObjType
	                    }
	                };

	            }else if(cObjType == 'sdo' || cObjType == 'businessentity' || cObjType == 'swatsdo' || cObjType == 'swatbusinessentity'){
	                oNewObject = {
	                    ui: cObjType,
	                    pageguid: existingCell[0].pageguid,
	                    guid: existingCell[0].objectinstanceguid,
	                    order: existingCell[0].sequence,
	                    position: existingCell[0].layoutposition,
	                    
	                    _custom: designerApp.akElm.props[existingCell[0].objectinstanceguid],
	                    config:{
	                        $name: existingCell[0].instancename + '/' + cObjType,
							header: existingCell[0].instancename,
							$objicon: akioma.repository.getObjIcon(existingCell[0].instancetypename),
							$objstyle: akioma.repository.getObjStyle(existingCell[0].instancetypename),	
	                        $objtype: cObjType
	                    }
	                };

	            }else{
					oNewObject = {
	                    ui: 'abstract',//existingCell[0].instancetypename,
	                    guid: existingCell[0].objectinstanceguid,
	                    order: existingCell[0].sequence,
	                    position: alphabet[i],
	                    _custom: designerApp.akElm.props[existingCell[0].objectinstanceguid],
	                    config:{
	                        $name: existingCell[0].instancename + '/' + cObjType,
	                        header: existingCell[0].instancename,
							$objicon: akioma.repository.getObjIcon(cObjType),
	                        $objstyle: akioma.repository.getObjStyle(cObjType),							
	                        $objtype: cObjType,
	                        $objname: existingCell[0].instancename
	                    }
	                };
				}
				

                
                branch[i] = oNewObject;
			}else{
				branch[i] = emptyCell();
			}

			
			this._parse_one(branch[i], id);


		}

		for (let i=count; i<branch.length; i++)
			this._remove(branch[i]);
			
		branch.splice(count);

		this.bus.callEvent("store:onDataRefresh",[ { type:"childs" }]);
	}

	_remove(item, removeFromStore = true){
		if (removeFromStore)
			this._removeFromStore(item);
		
		if (item.cells){
			for(var cell in item.cells){
				var subitem = item.cells[cell]; 
				if (removeFromStore)
					this._removeFromStore(subitem);
				delete this.pull[subitem.$id];
				delete item.cells[cell];
			}
		}
		delete item.cells;
		delete this.branch[item.$id];
	} 

	_removeFromStore(item){
		designerApp.akElm.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');

		var iIndex = designerApp.akElm.businessEntity.getIdFrom('objectinstanceguid', item.guid);

		if(iIndex){
			var oInsStore = designerApp.akElm.businessEntity.getStore('eSmartObjectInstance');
			oInsStore.remove(iIndex);
		}else{
			designerApp.akElm.businessEntity.switchJSDOWorkingRecord('eSmartPage');
			var iIndex = designerApp.akElm.businessEntity.getIdFrom('pageguid', item.guid);

			if(iIndex){
				var oPageStore = designerApp.akElm.businessEntity.getStore('eSmartPage');
				oPageStore.remove(iIndex);
			}
		}

		designerApp.akElm.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');
		console.log(item, ' needs to be removed from data store!');
	}

	reset(){
		this.pull = {};
		this.branch = {};
		this.undo.reset();
	}

	serialize(id){
		if (!id) id = this.branch[0][0].$id;

		var src = this.pull[id];
		var item = { ui:src.ui };
		if (src.protect) {
			item.config = copy(src.config);
			item.config.$name = src.config.$name;
		} else
			item.config = copy(src.config);

		

		var branch = this.branch[src.$id]; 
		if (branch){
			item.cells = [];
			for(let i=0; i<branch.length; i++)
				item.cells.push(this.serialize(branch[i].$id));
		}
		
		return item;
	}
	parse(cf){
		this.reset();
		this._parse_rec(0, [cf]);

		this.bus.callEvent("store:onDataLoad", []);
	}
	_parse_rec(id, arr, order){
		if (typeof order === "undefined")
			order = this.branch[id] = [].concat(arr);

		for (var i=0; i<arr.length; i++)
			this._parse_one(arr[i], id);
	}
	_parse_one(item, id){
		item.$parent = id;
		item.config = item.config || {};

		let subid = item.$id = uid();
		this.pull[subid] = item;

		let subcells = item.cells;
		if (subcells) 
			this._parse_rec(subid, subcells);
	}
	extendHierarchy(parent, name){
		let item = {};
		item.$parent = parent.$id;
		item.config = {};
		item.ui = name;
		let subid = item.$id = uid();
		this.pull[subid] = item;

		if(this.branch[parent.$id]) {
			this.branch[parent.$id].push(item);
		} else {
			this.branch[parent.$id] = [item];
		}
		this.bus.callEvent("store:onDataRefresh",[{ type:"" }]);
	}
}