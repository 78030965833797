import { DHXView } from "optimus.js";
import { VisualStageView } from "views/preview/visual.js";

export class PreviewArea extends DHXView {
	render() {
		var active = this.app.session.get("preview_tab", "a");
		this.root.attachSidebar({
			items: [
				{ id: "a", text: "Preview", active: (active == "a") }
			]
		});

		const myLayout = this.root.attachLayout({
			pattern: "1C",
			cells: [
				{ id: "a", text: "Preview", width: 100, header: false }
			]
		});
		this.root.hideHeader()
		myLayout.cells('a').hideHeader();
		this.show(VisualStageView, myLayout.cells("a"));
	}
}