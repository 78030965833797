import {DHXView} from "optimus.js";

export class VisualStageView extends DHXView{
	render(){
		var model = this.app.getService("UIState");

		var id = dhx.attachEvent("app$designerReady", (remote) => {
			this.$preview = remote;
			this.$preview.init(this.app);
			this.updatePreview();

			dhx.detachEvent(id);
		});

		let base = this.app.config.base || "./";
		this.root.attachHTMLString("<iframe src='"+base+"preview.html' style='width:100%; height:100%;' frameborder='0'></iframe>");

			
		this.attachEvent("store:onDataLoad", () => this.updatePreview() );
		this.attachEvent("store:onDataChange", () => this.updatePreview() );
		this.attachEvent("store:onDataRefresh", () => this.updatePreview() );

		this.attachEvent("preview:onItemClick", (id) => model.setCursor(id));
		this.attachEvent("preview:onTabSelect", (tab) => {
			var id = model.getCursor();
			if (tab && id && model.getItem(id) && model.getItem(id).config.active != tab){
				if(model.getItem(id).ui != undefined){
					let parentid = model.getItem(id).$parent;
					if(model.getItem(parentid).ui != 'tabbar') 
						model.updateItem(id, { active: tab }, true);
					else
						model.updateItem(parentid, { active: tab }, true);
				}
			}
			return true;
		});
		
	}
	updatePreview(){
		if (!this.$preview) return;

		var model = this.app.getService("UIState");
		this.$preview.render(model);
	}

	destructor(){
		this.$preview = null;
	}

}