var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"designer-preview-icon fa-3x\">\r\n                    <i class=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"obj") : depth0)) != null ? lookupProperty(stack1,"$objicon") : stack1), depth0)) != null ? stack1 : "")
    + "\" style=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"obj") : depth0)) != null ? lookupProperty(stack1,"$objstyle") : stack1), depth0)) != null ? stack1 : "")
    + "\"></i>\r\n                </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\nvar "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":12}}}) : helper)))
    + " = "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"parent") || (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parent","hash":{},"data":data,"loc":{"start":{"line":2,"column":15},"end":{"line":2,"column":27}}}) : helper))) != null ? stack1 : "")
    + ".attachHTMLString(`\r\n<div class='dhx_abstract placeholder-container'>\r\n    <span class='dhx_designer_text'>\r\n        <span class='designer_text'>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"obj") : depth0)) != null ? lookupProperty(stack1,"$objicon") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + "            <span class='text-info'>\r\n                <span>"
    + ((stack1 = alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"obj") : depth0)) != null ? lookupProperty(stack1,"$objtype") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\r\n                <span>"
    + ((stack1 = alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"obj") : depth0)) != null ? lookupProperty(stack1,"$objname") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\r\n                <span>"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"obj") : depth0)) != null ? lookupProperty(stack1,"$objdesc") : stack1), depth0))
    + "</span>\r\n            </span>\r\n        </span>\r\n        <button onclick=\\\"window.parent.akioma.repository.replaceDesignerPlaceholder( '"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"obj") : depth0)) != null ? lookupProperty(stack1,"$orgobjtype") : stack1), depth0))
    + "','"
    + alias4(((helper = (helper = lookupProperty(helpers,"guid") || (depth0 != null ? lookupProperty(depth0,"guid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"guid","hash":{},"data":data,"loc":{"start":{"line":17,"column":109},"end":{"line":17,"column":117}}}) : helper)))
    + "' );\\\">Replace</button>\r\n    </span>\r\n</div>`);\r\n  \r\n\r\n  ";
},"useData":true});