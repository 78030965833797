import {checkIfPanel} from "helpers/selector.js";

const code = {
	layout : require("code/layout.html"),
	grid : require("code/grid.html"),
	tree : require("code/tree.html"),
	form : require("code/form.html"),
	cell : require("code/cell.html"),
	calendar : require("code/calendar.html"),
	accordion : require("code/accordion.html"),
	carousel : require("code/carousel.html"),
	tabbar : require("code/tabbar.html"),
	chart : require("code/chart.html"),
	dataview : require("code/dataview.html"),
	list : require("code/list.html"),
	treegrid : require("code/treegrid.html"),
	treeview : require("code/treeview.html"),
	colorpicker : require("code/colorpicker.html"),
	combo : require("code/combo.html"),
	editor : require("code/editor.html"),
	slider : require("code/slider.html"),
	menu : require("code/menu.html"),
	ribbon : require("code/ribbon.html"),
	sidebar : require("code/sidebar.html"),
	swattoolbar : require("code/toolbar.html"),
	abstract : require("code/abstract.html"),
	akgrid: require("code/akgrid.html"),
	akgridcol: require("code/akgridcol.html"),
	html : require("code/html.html"),
	empty: require("code/empty.html"),
	businessentity: require("code/businessentity.html"),
	swatbusinessentity: require("code/swatbusinessentity.html"),
	swatsdo: require("code/swatsdo.html"),
	placeholder: require("code/placeholder.html"),
	sdo: require("code/sdo.html")
};

const letters = ["a","b","c","d","e","f","g","h","j","k","l","m","n", "o", "p","r", "s", "t", "u", "v", "w", "x", "y", "z"];
let names = {};

function getName(type){
	if (!names[type])
		names[type] = 1;
	else
		names[type]++;

	return type+names[type];
}

export function getTopLayout(){
	return "layout1";
}

export function writer(item, data, parent, preview){
	if (!item) return " ";

	if (!parent){
		parent = "";
		names = {};
	}

	var type = item.ui;
	var line = code[type];
	var name = getName(type);
	var childdata = data && data.getChildren(item.$id);
	var iDif = 0;
	if (childdata)
		for (var i = 0; i < childdata.length; i++){
			let aDataToSkip = ['swattoolbar', 'toolbar', 'swatribbon', 'ribbon', 'sdo', 'swatsdo', 'businessentity', 'swatbusinessentity'];
			// console.log('need to check here data to skip', childdata[i])
			let bBePlaceholder = false;
			try{
				bBePlaceholder = (childdata[i].ui == 'placeholder' && childdata[i].config.$orgobjtype.toLowerCase() == 'swatbusinessentity');

			}catch(e){
				console.log(e)
			}

			var bDatas =  (aDataToSkip.indexOf(childdata[i].ui) != -1 || bBePlaceholder);
			
			if(!bDatas){
				childdata[i].id = letters[iDif];
				iDif++;
			}
		}

	if (!line)
		dhtmlx.message("Not supported UI: "+type);



	var state = {
		parent, obj: item.config, name, id: item.$id, code: item.config.codem, cells:childdata, guid: item.guid
	};

	var res = "";
	// dont consider businessEntity or sdo as panel
	let bBePlaceholder = false;
	try{
		bBePlaceholder = (type == 'placeholder' && item.config.$orgobjtype.toLowerCase() == 'swatbusinessentity');
	}catch(e){
		console.log(e)
	}
	
	if (parent && ((type != 'sdo' && type != 'swatsdo' && type != 'swatbusinessentity' && type != 'businessentity') && !bBePlaceholder))
		res += code.cell(state);
	
	if(!bBePlaceholder) {
		res += line(state);	
	}
	
	if (state.code)
		res += `\n(${state.code})(${name})\n`;
	
	
	if (preview){
		let aDataToSkip = ['sdo', 'swatsdo', 'businessentity', 'swatbusinessentity', 'swatsdo', 'akgridcol', 'ribbon', 'swatribbon', 'swattoolbar', 'toolbar'];
		let bData = (aDataToSkip.indexOf(item.ui) == -1);
		if(aDataToSkip.indexOf(item.ui) == -1 && !bBePlaceholder){
			if (parent && (bData || bBePlaceholder))
				res += `if(${parent}) { ${parent}.cell.$id = ${item.$id}; }`;
			else
				res += `if(${name}) { ${name}.$id = ${item.$id}; }`;
		}

	}

	
	var iDif = 0;
	if (childdata) { 
		// console.log('need to check here businessEntity placeholder?', childdata);
		let aDataToSkip = ['akgridcol', 'ribbon', 'toolbar', 'swatribbon', 'swattoolbar', 'sdo', 'swatsdo', 'swatbusinessentity', 'businessentity'];
		for (var i = 0; i < childdata.length; i++) {
			if(!childdata[i].ui || !checkIfPanel(childdata[i])) {
				let bBePlaceholder = false;
				try{
					bBePlaceholder = (childdata[i].ui == 'placeholder' && childdata[i].config.$orgobjtype.toLowerCase() == 'swatbusinessentity');

				}catch(e){
					console.log(e)
				}

				if(aDataToSkip.indexOf(childdata[i].ui) != -1 || bBePlaceholder)
					res += "\n\n" + writer(childdata[i], data, `${name}`, preview);
				else{
					res += "\n\n" + writer(childdata[i], data, `${name}.cells("${letters[iDif]}")`, preview);
					iDif++;
				}
			}			
		}
	}


	return res;
}