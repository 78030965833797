export const grid = [
	{
		label:"Grid",
		type:"group"
	},
	{
		name: "header",
		label: "Header",
		type: "input"
	},
	{
		name: "columns",
		label: "Columns",   
		type: "input"
	},
	{
		name: "widths",
		label: "Widths",
		type: "input"
	},
	{
		name: "date_format",
		label: "Date format",
		type: "input"
	},
	{
		name: "multiline",
		label: "Multiline",
		type: "checkbox"
	},
	{
		name: "col_move",
		label: "Columns moving",
		type: "checkbox"
	},
	{
			// !!!  НЕ РАБОТАЕТ
		name: "header_menu",
		label: "Header menu",
		type: "checkbox"
	},
	{
		name: "no_header",
		label: "Hide header",
		type: "checkbox"
	},
	{
		name: "splt",
		label: "Frozen columns",
		type: "input"
	},
	
	
	{
		label: "Rows grouping",
		type: "group"
	},
	{
		name: "group",
		label: "Group Column",
		type: "input"
	},
	{
		name: "group_collapse",
		label: "Collapse groups",
		type: "checkbox"
	},
	
	
	{
		label: "Selection",
		type: "group"
	},
	{
		name: "multiselect",
		label: "Enable multiselect",
		type: "checkbox"
	},
	{
		name: "block",
		label: "Block selection",
		type: "checkbox"
	},
	{
		name: "marker",
		label: "Enable markers",
		type: "checkbox"
	},
	{
		name: "mouse_nav",
		label: "Enable mouse navigation",
		type: "checkbox"
	},
	
	
	{
		label: "Elements style",
		type: "group"
	},
	{
		name: "hdr_style",
		label: "Header style",
		type: "input"
	},
	{
		name: "cell_style",
		label: "Cell style",
		type: "input"
	},
	{
		name: "sel_cell_style",
		label: "Selected cell style",
		type: "input"
	},
	{
		name: "sel_row_style",
		label: "selected row style",
		type: "input"
	},
	
	
	{
		label: "Filter",
		type: "group"
	},
	{
		name: "grid_filter_ind",
		label: "Filter column",
		type: "input"
	},
	{
		name: "grid_filter",
		label: "Filter value",
		type: "input"
	},
	
	
	{
		label: "Grid sorting",
		type: "group"
	},
	{
		name: "sort_ind",
		label: "Sorting column",
		type: "input"
	},
	{
		name: "sort_type",
		label: "Sorting type",
		type: "input"
	},
	{
		name: "sort_dir",
		label: "Sorting direction",
		type: "select",
		options: ["","asc","desc"]
	},
	{
		name: "stable_sort",
		label: "Stable sorting",
		type: "checkbox"
	},
	
	
	{
		label: "Drag and drop",
		type: "group"
	},
	{
		name: "drag_drop",
		label: "Enable drag'n'drop",
		type: "checkbox"
	},
	{
		name: "drag_order",
		label: "Drag order",
		type: "checkbox"
	},
	{
		name: "mercy_drag",
		label: "Mercy drag",
		type: "checkbox"
	},
	{
		name: "gr_drag_behave",
		label: "Drag behavior",
		type: "select",
		options: ["","child","complex","sibling","sibling-next","complex-next"]
	},

	
	{
		label: "Cookies saving",
		type: "group"
	},
	{
		name: "sort_save",
		label: "Sorting saving",
		type: "checkbox"
	},
	{
		name: "sort_save_name",
		label: "Cookie name",
		type: "input"
	},
	{
		name: "sort_save_param",
		label: "Cookie parameters",
		type: "input"
	},
	{
		name: "order_save",
		label: "Columns order saving",
		type: "checkbox"
	},
	{
		name: "order_save_name",
		label: "Cookie name",
		type: "input"
	},
	{
		name: "order_save_param",
		label: "Cookie parameters",
		type: "input"
	},
	{
		name: "sizes_save",
		label: "columns sizes saving",
		type: "checkbox"
	},
	{
		name: "sizes_save_name",
		label: "Cookie name",
		type: "input"
	},
	{
		name: "sizes_save_param",
		label: "Cookie parameters",
		type: "input"
	},
	{
		name: "hidden_save",
		label: "Hidden columns saving",
		type: "checkbox"
	},
	{
		name: "hidden_save_name",
		label: "Cookie name",
		type: "input"
	},
	{
		name: "hidden_save_param",
		label: "Cookie parameters",
		type: "input"
	},
	
	
	{
		label: "Cells merging",
		type: "group"
	},
	{
		name: "colspan",
		label: "Enable colspan",
		type: "checkbox"
	},
	{
		name: "rowspan",
		label: "Enable rowspan",
		type: "checkbox"
	},
	
	
	{
		label: "Keyboard support",
		type: "group"
	},
	{
		name: "key_nav",
		label: "Disable keyboard navigation",
		type: "checkbox"
	},
	{
		name: "excel_keymap",
		label: "Enable Excel keymap",
		type: "checkbox"
	},
	{
		name: "access_keymap",
		label: "Enable Access keymap",
		type: "checkbox"
	},
	{
		name: "tab_order",
		label: "Set tab order",
		type: "input"
	},
	{
		name: "edit_tab",
		label: "Tab only editable fields",
		type: "checkbox"
	},
	
	
	{
		label: "Big data operating",
		type: "group"
	},
	{
		label: "Smart rendering mode",
		type: "group"
	},
	{
		name: "srnd",
		label: "Smart rendering enabling",
		type: "checkbox"
	},
	{
		name: "srnd_count",
		label: "Count of rows in a request",
		type: "input"
	},
	
	{
		label: "Distributed parsing mode",
		type: "group"
	},
	{
		name: "distrib_parse",
		label: "Distributed parsing enabling",
		type: "checkbox"
	},
	{
		//default value is required
		name: "distrib_count",
		label: "Count of parsing rows",
		value: "10",
		type: "input"
	},
	{
		//default value is required
		name: "distrib_delay",
		label: "Delay after the parsing",
		value: "250",
		type: "input"
	},
	
	
	{
		label: "Paging mode",
		type: "group"
	},
	{
		name: "pgn",
		label: "Paging enabling",
		type: "checkbox"
	},
	{
		//default value is required
		name: "page_size",
		label: "Rows on page",
		value: "15",
		type: "input"
	},
	{
		//default value is required
		name: "pages_in_grp",
		label: "Page selectors number",
		value: "5",
		type: "input"
	},
	{
		name: "pgn_skin",
		label: "Paging skin",
		options:["default","bricks","toolbar"],
		type: "select"
	},

	
	{
		label: "Paging localization",
		type: "group"
	},
	{
		name: "pgn_loc_results",
		label: "Results",
		type: "input",
		value: ""
	},
	{
		name: "pgn_loc_records",
		label: "Records from",
		type: "input",
		value: ""
	},
	{
		name: "pgn_loc_to",
		label: "to",
		type: "input",
		value: ""
	},
	{
		name: "pgn_loc_page",
		label: "Page",
		type: "input",
		value: ""
	},
	{
		name: "pgn_loc_perpage",
		label: "rows per page",
		type: "input",
		value: ""
	},
	{
		name: "pgn_loc_first",
		label: "To first Page",
		type: "input",
		value: ""
	},
	{
		name: "pgn_loc_previous",
		label: "Previous Page",
		type: "input",
		value: ""
	},
	{
		name: "pgn_loc_found",
		label: "Found records",
		type: "input",
		value: ""
	},
	{
		name: "pgn_loc_next",
		label: "Next Page",
		type: "input",
		value: ""
	},
	{
		name: "pgn_loc_last",
		label: "To last Page",
		type: "input",
		value: ""
	},
	{
		name: "pgn_loc_of",
		label: "of",
		type: "input",
		value: ""
	},
	{
		name: "pgn_loc_notfound",
		label: "No Records Found",
		type: "input",
		value: ""
	},
	{
		name: "pgn_local",
		label: "Apply",
		type: "checkbox"
	}
];