export const colorpicker = [
	{
		label:"colorpicker",
		type:"group"
	},{
		name:"memory",
		label: "Show Memory",
		type:"checkbox"
	},{
		name:"skin",
		label: "Skin",
		options:["dhx_skyblue","dhx_web","dhx_terrace"],
		type:"select"
	}
];
