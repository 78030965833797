import {writer,getTopLayout} from "code/index.js";
import {extractId, showBorder, checkIfPanel} from "../helpers/selector.js";

export class PreviewManager{
	constructor(host, owner){
		this.win = host;
		this.currentSelection = null;
		this.currentSelectionSrc = null;
		this.currentBorder = null;
		owner.dhx.callEvent("app$designerReady", [this, host]);
	}

	init(app){
		var t1 = dhtmlXTabBar.prototype._fixTabsOfs;
		this.app = app;
		dhtmlXTabBar.prototype._fixTabsOfs = function(){
			var t2 = this._doOnClick;
			this._doOnClick = function(a,c){
				if (app.callEvent("preview:onTabSelect", [a]))
					return t2.call(this, a,c);
			};
			return t1.call(this);
		};
		var s = dhtmlXSideBar.prototype._setItemActive;
		dhtmlXSideBar.prototype._setItemActive = function(a,c){
			if (!c || app.callEvent("preview:onTabSelect", [a]))
				return s.call(this, a,c);
		};

		dhtmlxEvent(window.document.body, "mousedown", (e)=>{
			let id = extractId(e);
			if(!id) return;

			this.currentSelection = id.id;
			this.currentSelectionSrc = id.src;
			showBorder(id.src);

			app.callEvent("preview:onItemClick", [id.id]);
		});
		

		// reposition cursor blue over selected on resize
		app.root.dataObj.attachEvent("onPanelResizeFinish", (ids) => {
			this.showBorderSelection();
			
			try {
				this.saveLayoutSettings(app, ids);
			} catch (e) {
				console.error(e);
			}

		});

		// reposition if parent window was resized also
		if(app.akElm){
			let oWinCell = app.akElm.dynObject.container.controller.dhx;
			oWinCell.attachEvent("onResizeFinish", () => {
				this.showBorderSelection();
			});
		}
		

		dhtmlxEvent(window.document.body, "dblclick", (e)=>{
			let id = extractId(e);
			if(!id) return;

			this.currentSelection = id.id;
			showBorder(id.src);
			if(app.config.afterDblClick)
				app.config.afterDblClick(app.getService('UIState').getItem(id.id));
		});

		app.attachEvent("store:onCursor", (id, old, item) => {
			if (this.currentSelection == id) return;
			
			this.selectClosestTab(id);
			
			showBorder(window.$toplayout, checkIfPanel(item) ? item.$parent : id);
			this.currentSelection = id;
		});
	}

	selectClosestTab(id) {
		try {
			const model = this.app.getService('UIState');
			const itemObj = model.getItem(id);
			const parentObj = model.getItem(itemObj.$parent);
			const parentid = model.getItem(id).$parent;

			if(itemObj && itemObj.$objtype === 'page') {
				if(parentObj.ui === 'tabbar') {
					model.updateItem(parentid, { active: itemObj.id }, true);
				}
				for(let repoObj of window.$toplayout.items) {
					if(repoObj.dataObj && repoObj.dataObj._setTabActive) {
						repoObj.dataObj._setTabActive(itemObj.id);
					}
				}
			}else if(parentObj && parentObj.$objtype === 'page') {
				if(model.getItem(parentObj.$parent).ui === 'tabbar') {
					model.updateItem(parentObj.$parent, { active: parentObj.id }, true);
				}
				for(let repoObj of window.$toplayout.items) {
					if(repoObj.dataObj && repoObj.dataObj._setTabActive) {
						repoObj.dataObj._setTabActive(parentObj.id);
					}
				}
			}
			
		} catch (e) {
			console.error(e);
		}
	}

	showBorderSelection() {
		setTimeout(() => {
			if(this.currentSelection){
				showBorder(this.currentSelectionSrc);
				this.app.callEvent("preview:onItemClick", [this.currentSelection]);
			}
		}, 1000);
	}

	render(data){
		var top = data.getRoot();
		var code = new Function("\ntry{\n " + writer(top, data, "", true) + "\nif(typeof " + getTopLayout() + " != 'undefined'){\n return "+getTopLayout()+"; \n} \n}catch(e){ \nconsole.warn(e); \n if(layout1) \n return layout1; \n}");

		if (window.$toplayout)
			window.$toplayout.unload();
		window.$toplayout = code();
		
		if (this.currentSelection)
			showBorder(window.$toplayout, this.currentSelection);
		this.bIsLoaded = true;
		
		this.loadPreviewLayoutSettings();
		this.setPreviewLayoutResizeEvents();
	}
	/**
	 * Method for loading preview cell nested layout settings
	 *
	 * @return  {void}  
	 */
	loadPreviewLayoutSettings() {
		const localStorageSettings = window.localStorage.getItem("ak_designer_preview");
		
		if(!localStorageSettings || !window.$toplayout)
			return;
		const model = this.app.getService("UIState");
		const objectMasterName = model.getItem(window.$toplayout.$id).config.$objname;

		const existingSettings = JSON.parse(localStorageSettings);
		const objectSettings = existingSettings[objectMasterName];

		if(objectSettings) {
			window.$toplayout.forEachItem((cell) => {
				// console.log('saving cell ', cell, cell._idd, cell.getDimensions(), model.getItem(cell._idd));
				const cellItem = model.getItem(cell.cell.$id);
				const settings = objectSettings.find(setting => setting.name === cellItem.config.$objname);

				if(settings) {
					this.loadLayoutSettings(cell, settings);
				}
	
				if(cell.dataObj instanceof window.dhtmlXTabBar) {
					const tabbar = cell.dataObj;
					// for each page save the layout settings
					this.loadPageLayoutSettings(tabbar, objectSettings);
				}
			});
		}

	}
	/**
	 * Method for loading the layout settings for panels in pages
	 *
	 * @param   {dhtmlxTabbar}  tabbar          Tabbar object
	 * @param   {object}  objectSettings  Layout settings
	 *
	 * @return  {void}                 
	 */
	loadPageLayoutSettings(tabbar, objectSettings) {
		const model = this.app.getService("UIState");
		// for each page save the layout settings
		tabbar.forEachCell((tabCell) => {
			const layoutCell = tabCell.dataObj;
			layoutCell.forEachItem((cellEl) => {
				const pageItem = model.getItem(tabCell.cell.$id);
				const settings = objectSettings.find(setting => setting.name === pageItem.config.$objname && setting.panel === model.getItem(cellEl.cell.$id).position);
				if(settings) {
					this.loadLayoutSettings(cellEl, settings);
				}
			});
		});
	}
	/**
	 * Method for setting the layout settings
	 *
	 * @param   {LayoutObject}  cell      
	 * @param   {object}  settings 
	 *
	 * @return  {void}      
	 */
	loadLayoutSettings(cell, settings) {
		const onePercentH = window.$(window.$toplayout.base).height() / 100;
		const onePercentW = window.$(window.$toplayout.base).width() / 100;
		cell.setWidth(settings.width * onePercentW);
		cell.setHeight(settings.height * onePercentH);
		if(settings.collapsed) {
			cell.collapse();
		}
	}
	/**
	 * Method for setting panel resize events to save preview area layouts
	 *
	 */
	setPreviewLayoutResizeEvents() {
		if(!window.$toplayout)
			return;

		window.$toplayout.attachEvent("onPanelResizeFinish", () => {
			this.savePreviewLayoutSettings();
		});

		window.$toplayout.attachEvent("onCollapse", () => {
			this.savePreviewLayoutSettings();
		});

		window.$toplayout.forEachItem((cell) => {
			if(cell.dataObj && cell.dataObj instanceof window.dhtmlXTabBar) {
				const tabbar = cell.dataObj;
				// for each page save the layout settings
				tabbar.forEachCell((tabCell) => {
					const layoutCell = tabCell.dataObj;
					layoutCell.attachEvent("onPanelResizeFinish", () => {
						this.savePreviewLayoutSettings();
					});
					layoutCell.attachEvent("onCollapse", () => {
						this.savePreviewLayoutSettings();
					});
				});
			} else {
				cell.attachEvent("onPanelResizeFinish", () => {
					this.savePreviewLayoutSettings();
				});
				cell.attachEvent("onCollapse", () => {
					this.savePreviewLayoutSettings();
				});
			}
		});
	}
	/**
	 * Method for saving preview cell layout settings
	 *
	 */
	savePreviewLayoutSettings() {

		if(!window.$toplayout)
			return;
		const model = this.app.getService("UIState");
		const objectMasterName = model.getItem(window.$toplayout.$id).config.$objname;
		const objectSettings = [];

		window.$toplayout.forEachItem((cell) => {
			// console.log('saving cell ', cell, cell._idd, cell.getDimensions(), model.getItem(cell._idd));
			const cellItem = model.getItem(cell.cell.$id);

			const wPercent = cell.getWidth() / window.$(window.$toplayout.base).width() * 100;
			const hPercent = cell.getHeight() / window.$(window.$toplayout.base).height() * 100;
			const isCollapsed = cell.isCollapsed();

			objectSettings.push({
				name: cellItem.config.$objname,
				width: wPercent,
				height: hPercent,
				collapsed: isCollapsed
			});

			if(cell.dataObj instanceof window.dhtmlXTabBar) {
				const tabbar = cell.dataObj;
				// for each page save the layout settings
				tabbar.forEachCell((tabCell) => {
					// console.log('inside tabbar cell', pageCell, model.getItem(cell._idd));
					
					const layoutCell = tabCell.dataObj;
					layoutCell.forEachItem((cellEl) => {
						// console.log('saving page cell ', pageCell._idd, pageCell, pageCell.getDimensions(), model.getItem(cell._idd));
						const pageItem = model.getItem(tabCell.cell.$id);
						const wPercent = cellEl.getWidth() / window.$(window.$toplayout.base).width() * 100;
						const hPercent = cellEl.getHeight() / window.$(window.$toplayout.base).height() * 100;
						const isCollapsed = cellEl.isCollapsed();
					
						objectSettings.push({
							name: pageItem.config.$objname,
							panel: model.getItem(cellEl.cell.$id).position,
							width: wPercent,
							height: hPercent,
							collapsed: isCollapsed
						});
					});
				});
			}
		});

		const localStorageSettings = window.localStorage.getItem("ak_designer_preview");
		let existingSettings = JSON.parse(localStorageSettings) || {};
		const previewLayoutSettings = {
			[objectMasterName]: objectSettings
		};
		Object.assign(existingSettings, previewLayoutSettings);
		window.localStorage.setItem("ak_designer_preview", JSON.stringify(existingSettings));
	}

	/**
	 * Method for saving user profile layout settings for one or multiple given cell ids
	 * @param {object} app akioma controller
	 * @param {array} ids multiple cell ids
	 * @instance
	 * @returns {void}
	 */
	saveLayoutSettings(app, ids) {
		// save window settings including panel sizes

		const childSizes = [];
		if (ids){
			for( const i in ids ) {
				const cell = app.root.dataObj.cells(ids[i]);
				const cellWidth = cell.getWidth();
				const cellHeight = cell.getHeight();
				if (cellWidth < 100 ) {
					cell.setWidth(100);
				}

				childSizes.push({
					id: ids[i],
					height: cellHeight,
					width: cellWidth,
				});
				
			}

			// save window settings including panel sizes
			if(this.bIsLoaded) {
				app.akElm.dynObject.saveUserProfileSettings(childSizes);
				this.savePreviewLayoutSettings();
			}
		}
		
	}
}