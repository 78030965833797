export const calendar = [
	{
		label:"Calendar",
		type:"group"
	},{
		name:"iframe",
		label:"Enable Iframe",
		type:"checkbox"
	},{
		name:"interval",
		label:"Minutes Interval",
		options:["1","5","10","15"],
		type:"select"
	},{
		name:"skin",
		label:"Skin",
		options:["dhx_skyblue","dhx_web","dhx_terrace"],
		type:"select"
	},{
		name:"startDay",
		label:"Week Start Day",
		options:["1","2","3","4","5","6","7"],
		type:"select"
	},{
		name:"time",
		label:"Show Time",
		type:"checkbox"
	},{
		name:"today",
		label: "Show today",
		type:"checkbox"
	},{
		name:"weekNumber",
		label:"Show Week Numbers",
		type:"checkbox"
	},{
		name: "dateFormat",
		label: "Date format",
		type: "input",
		fieldType: "string"
	}
];