var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "var "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":12}}}) : helper)))
    + " = "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"parent") || (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parent","hash":{},"data":data,"loc":{"start":{"line":1,"column":15},"end":{"line":1,"column":27}}}) : helper))) != null ? stack1 : "")
    + ".attachTreeView();\r\n	"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":2,"column":9}}}) : helper)))
    + ".loadStruct([\r\n			{id: 1, text: \"Books\", open: 1, items: [\r\n	        {id: 2, text: \"Turned at Dark / C. C. Hunter\"},\r\n	        {id: 3, text: \"Daire Meets Ever / Alyson Noël\"},\r\n	        {id: 4, text: \"Socs and Greasers / Rob Lowe\"},\r\n	        {id: 5, text: \"Privacy and Terms.pdf\"},\r\n	        {id: 6, text: \"Licence Agreement.pdf\"}\r\n	    ]}\r\n	]);\r\n";
},"useData":true});