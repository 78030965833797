import {DHXView}    from "optimus.js";
import { checkIfPanel } from "helpers/selector.js";

export class TreeMenu extends DHXView {
	render() {

        this.ui = new dhtmlXMenuObject({
			context: true,
			items: this.app.getService("UIMenu").getTreeMenu(),
		});

		let model = this.app.getService("UIState");
		let snippet = this.app.getService("UISnippets");

		let uimenu = this.ui;
		this.ui.attachEvent("onShow", (id) => {
			var oitm = model.pull[model.getCursor()];
			var uitype = oitm.ui;

			// for page show remove page for remove show basic remove
			// show/hide launch master object option if page or not page select in tree
			if (oitm.$objtype && oitm.$objtype === 'page') {
				uimenu.showItem('removePage');
				uimenu.hideItem('remove');
				uimenu.hideItem('launchMaster');
			} else {
				uimenu.hideItem('removePage');
				uimenu.showItem('remove');
				uimenu.showItem('launchMaster');
			}

			let isDesignerPlaceholder = oitm.ui === 'placeholder';
			// for any placeholder object
			if(isDesignerPlaceholder){
				uimenu.showItem("replace");
			}else{
				uimenu.hideItem("replace");
			}
			
			// for tabbar show add new page
			if (uitype == "tabbar" || oitm.$objtype && oitm.$objtype == "tabbar") {
				uimenu.showItem("addpage");
			} else {
				uimenu.hideItem("addpage");
			}
		});
		
		this.ui.attachEvent("onClick", (id) => {
			var item = model.getItem(model.getCursor());
			// call custom event code if any
			if(model.config.onTreeContextMenuClick)
				var cNewType = model.config.onTreeContextMenuClick(id, item);
			if(id == "addpage" || id == "replace" || id == "launchMaster") 
				return true;
			// when delete will replace with empty cell
			if(cNewType == "empty"){
				item.config.header = "";
				var newItem = { ui:"empty", guid:"", config:{ content:"<span class='dhx_designer_text'>Cell </span>", header: ''} };
				model.replace(item.$id, newItem);
				item.guid = ''; 
				item.pageguid = '';
				item.$objtype = '';
				item._custom = [];
				// reset to position cursor again
				model._cursor = '';
				model.bus.callEvent("store:onCursor", [item.$id, 0, item]);

				return true;
			//when delete will remove the cell/in the case of businessentity for example
			}else if(cNewType == ''){
				var item = model.pull[model.getCursor()];
                var aExisting = model.branch[item.$parent];
                aExisting.splice(aExisting.indexOf(item), 1);
            
                model._parse_rec(item.$parent, aExisting);

                model.bus.callEvent("store:onDataRefresh",[{}]);

                model._cursor = "";
                model.bus.callEvent("store:onCursor", [item.$parent, 0, model.pull[item.$parent]]);

			}else if(cNewType && cNewType.done){
				// remove node
				try{
					cNewType.done(function(result){
						if (result != 0)
                            return;
                        
						var item = model.pull[model.getCursor()];
	                    var aExisting = model.branch[item.$parent];
	                    aExisting.splice(aExisting.indexOf(item), 1);
	                
	                    model._parse_rec(item.$parent, aExisting);

	                    model.bus.callEvent("store:onDataRefresh",[{}]);

	                    model._cursor = '';
	                    model.bus.callEvent("store:onCursor", [item.$parent, 0, model.pull[item.$parent]]);
					});
					

                }catch(e){
                    console.warn('Adding to tree branch failed!', e)
                }
			}else{
				// do what dhtmlx normally does
				if (checkIfPanel(id)) {
					let parentAsPanel = checkIfPanel(item);
					var values = {};

					if (parentAsPanel) {
						values[item.ui] = false;
						model.replace(item.$id, id);
						item = model.getItem(item.$parent);
					} else {
						let children = model.getChildren(item.$id);
						if (children && children.length) {
							for (let i = 0; i < children.length; i++) {
								if (checkIfPanel(children[i])) {
									parentAsPanel = true;
									values[children[i].ui] = false;
									model.replace(children[i].$id, id);
								}
							}
						}
					}

					values[id] = true;
					model.updateItem(item.$id, values);
					model.bus.callEvent("store:onCursor", [item.$id, 0, item]);
					if (!parentAsPanel) {
						model.extendHierarchy(item, id);
					}
				} else {
					if (checkIfPanel(item)) {
						item = model.getItem(item.$parent);
					}
					if (id.indexOf("c_") === 0)
						model.replace(item.$id, snippet.getItem(id));
					else
						model.replace(item.$id, id);
				}
			}


			
		});
	}
}

